import { Button } from "@/components/ui/button";

export default function PelajariLebihLanjut() {
  return (
    <Button
      variant={"outline"}
      className="mt-10 bg-primary hover:shadow"
      onClick={() => {
        document
          .querySelector("#fitur")
          ?.scrollIntoView({ behavior: "smooth" });
      }}
    >
      Pelajari Lebih Lanjut
    </Button>
  );
}
